<template>
  <div>
    <div class="destination-row">
      <!-- Intro -->
      <div class="destination-item d-intro">
        <div class="v4-section-header-light color-gradient-shark d-intro-header">Where do you want to sail?</div>
        <p class="v4-base-paragraph color-storm">
          <i>Andromeda</i> covers a lot of water, please tell us where you would enjoy exploring.
        </p>
      </div>

      <!-- VI -->
      <div class="destination-item" :class="isDestinationActive('Virgin Islands')" @click="toggleActive('Virgin Islands')">
        <img alt="Virgin islands drone shot" src="https://cloud.bartonandgray.com/library/adventure-cruising/top-destinations/vi-banner.jpg">
        <div class="bg-color-gradient-shark">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova">The Virgin Islands</div>
        </div>
      </div>

      <!-- ST Barths -->
      <div class="destination-item" :class="isDestinationActive('St Barths')" @click="toggleActive('St Barths')">
        <img style="" alt="Couple enjoying life on Andromeda" src="https://cloud.bartonandgray.com/library/adventure-cruising/top-destinations/st-barths-banner.jpg">
        <div class="bg-color-gradient-shark">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova">St. Barths &amp; Anguilla</div>
        </div>
      </div>
    </div>

    <div class="destination-row">

      <!-- Sig Cruises -->
      <div class="destination-item" :class="isDestinationActive('Signature Cruises')" @click="toggleActive('Signature Cruises')">
        <img style="" alt="Andromeda anchored near concert" src="https://cloud.bartonandgray.com/library/adventure-cruising/landing/sig-cruises-banner-small.jpg">
        <div class="bg-color-gradient-shark">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova">Signature Cruises</div>
        </div>
      </div>

      <!-- Northeast -->
      <div class="destination-item" :class="isDestinationActive('The Northeast')" @click="toggleActive('The Northeast')">
        <img style="" alt="Couple enjoying life on Andromeda" src="https://cloud.bartonandgray.com/library/adventure-cruising/top-destinations/vi-banner.jpg">
        <div class="bg-color-gradient-shark">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova">The Northeast</div>
        </div>
      </div>

      <!--- Bahamas -->
      <div class="destination-item" :class="isDestinationActive('Bahamas')" @click="toggleActive('Bahamas')">
        <img style="" alt="Overhead drone shot of Andromeda" src="https://cloud.bartonandgray.com/library/adventure-cruising/top-destinations/bahamas-banner.jpg">
        <div class="bg-color-gradient-shark">
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4913_45809)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM26.9008 11.4362C27.6929 10.1775 27.3146 8.51497 26.0559 7.72288C24.7972 6.93079 23.1347 7.30908 22.3426 8.56781L15.5085 19.4281L10.607 16.523C9.32765 15.7647 7.67579 16.1872 6.9175 17.4665C6.1592 18.7459 6.58163 20.3978 7.86101 21.1561L15.0181 25.3981C16.2734 26.1421 17.893 25.7509 18.6702 24.5158L26.9008 11.4362Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_4913_45809">
                <rect width="35" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div class="v4-base-header-nova">The Bahamas</div>
        </div>
      </div>
    </div>

    <!-- Button -->
    <div v-if="selectedDestinations.length > 0" @click="$emit('close', selectedDestinations)" style="width: 300px; max-width: 100%; margin: 3em 0; margin-left: auto; padding-right: 1.5em">
      <div class="v4-download-button bg-color-gradient-cloud">
        <div class="soft-square bg-color-gradient-sky">
          <img style="width: 30px;" class="filter-gold" src="https://cloud.bartonandgray.com/library/ux/icons/chevrons/arrow-next.svg">
        </div>
        <div class="button-label color-gradient-shark">NEXT: TIMEFRAME</div>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['destinations']);

  let selectedDestinations = ref(props.destinations);

  function isDestinationActive(destinationName){
    if (selectedDestinations.value.includes(destinationName)) {
      return 'active';
    } else{
      return;
    }
  }

  function toggleActive(destinationName){
    if (selectedDestinations.value.includes(destinationName)) {
      selectedDestinations.value.splice(selectedDestinations.value.indexOf(destinationName), 1);      
    } else {
      selectedDestinations.value.push(destinationName);
    }
  }

</script>

<style scoped lang="scss">
  @import '../../../../assets/stylesheets/v3/abstracts/colors';

  .destination-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 3em;
    @media only screen and (max-width: 1281px){
      flex-direction: column;
      gap: 2em;
      margin-bottom: 2em;
      padding: 0 1em;
    }
  }

  .destination-item{
    width: 30%;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    &:hover{
      filter: brightness(90%);
    }
    @media only screen and (max-width: 1281px){
      width: 100%;
    }

    img{
      max-width: 100%;
      vertical-align: bottom;
    }
    div{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5em;
      padding-left: 1em;

      .v4-base-header-nova{
        color: $v3-white-cap;
      }
    }

    &.active{
      // Colors check mark
      path{
        fill: $v3-gold;
      }

      .v4-base-header-nova{
        color: $v3-gold; // Fallback color for unsupported browsers
        background-image: $gradient-sun;
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
      }
    }

    &.d-intro{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      cursor: auto;
      &:hover{
        filter: none;
      }
    }

    .d-intro-header{
      padding: 0;
    }
  }
</style>
  