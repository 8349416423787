<!--

    Author: dhersey 11/06/2024
    Desc: Component to cycle through images

    Props:
      :gallery (Array) - Array of images to cycle through
      :rate (Number) - Number of seconds before each cycle
      :delay (Number) - Number of seconds before cycle starts
      :maxw (Number) - Max width in px
      :maxh (Number) - Max Height in px
-->

<template>
  <div class="cycle-wrapper">
    <div v-for="image, count in props.gallery" v-bind:key="count" :class="isActive(count)" :style="backgroundSetter(image)"></div>
  </div>
</template>

<script setup>
  import { ref, onMounted, onUnmounted } from "vue";

  const props = defineProps({
    gallery: Array,
    interval: Number,
  });

  let slide_index = ref(0);

  function isActive(x){
    if(x == slide_index.value){
      return 'active'
    } else {
      return
    }
  }

  function backgroundSetter(z){
    return 'background-image: url("' + z + '")'
  }

  function increase(){
    if(slide_index.value > props.gallery.length - 2){
      slide_index.value = 0;
    } else {
      slide_index.value++;
    }
  }

  let intervalId

  onMounted(() => {
    intervalId = setInterval(increase, props.interval)
    
  });

  onUnmounted(() => {
    clearInterval(intervalId)
  });

</script>

<style scoped lang="scss">
  $img-size-l: 460px;
  $img-size-s: 360px;

  .cycle-wrapper{
    width: $img-size-l;
    height: $img-size-l;
    max-width: 100%;
    position: relative;

    div{
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      width: $img-size-l;
      height: $img-size-l;
      max-width: 100%;
      opacity: 0;
      transition: opacity 1s ease-in-out;
      &.active{
        opacity: 1;
      }
    }

    @media only screen and (max-width: 1281px){
      width: $img-size-s;
      height: $img-size-s;
      max-width: 100%;
      margin: 0 auto;
      div{
        width: $img-size-s;
        height: $img-size-s;
        max-width: 100%;
      }
    }
  }

  img{
    width: 100%;
    opacity: 0;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    &.active{
      opacity: 1;
    }
  }

  
</style>